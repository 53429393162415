import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class LeadAttendanceService
{
    apiUrl = environment.apiUrl + "/lead";
    attendance;
    onAttendanceLeadsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onAttendanceLeadsChanged = new BehaviorSubject({});
    }

    listInAttendanceByRealtor(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/attendance/list").subscribe((response) => {
                this.attendance = response;
                this.onAttendanceLeadsChanged.next(this.attendance);
                resolve(response);
            }, reject);
        });
    }
}

import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule,
    MatToolbarModule, MatTooltipModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { CurrencyMaskModule } from "ng2-currency-mask";

import { FinancingIndexerService } from 'app/main/service/financing-indexer.service';

import { FinancingInstallmentIndexerFormDialogComponent } from 'app/main/financial/incoming/financing-installment-indexer-form/financing-installment-indexer-form.component';

@NgModule({
    declarations: [
        FinancingInstallmentIndexerFormDialogComponent
    ],
    imports: [
        FuseSharedModule,
        CurrencyMaskModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatToolbarModule,
        MatTooltipModule,

        FormsModule,
        ReactiveFormsModule,

        NgxMaskModule,

        TranslateModule
    ],
    providers : [
        FinancingIndexerService
    ],
    entryComponents: [
        FinancingInstallmentIndexerFormDialogComponent
    ],
})
export class FinancingInstallmentIndexerFormModule
{
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'app/environment/environment';

import { AuthenticationService } from 'app/auth/authentication.service';

import { Unity } from 'app/main/development/unities/unity.model';

@Injectable()
export class VUnityHistoryCommissionService
{
    apiUrl = environment.apiUrl + "/outcoming/commission";
    
    commissions: any[];
    onCommissionsChanged: BehaviorSubject<any>;
    
    commission: any;
    onCommissionChanged: BehaviorSubject<any>;

    constructor(
        private _authService: AuthenticationService,
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onCommissionsChanged = new BehaviorSubject([]);
        this.onCommissionChanged = new BehaviorSubject([]);
    }

    listByUnity(unity: Unity): Promise<any> {
        let request = {
            unity: unity
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/list', {...request}).subscribe((response: any) => {
                this.commissions = response;

                this.onCommissionsChanged.next(this.commissions);
                resolve(this.commissions);
            }, reject);
        });
    }
    
    listByUser(): Promise<any> {
        let user = this._authService.currentUserValue;

        console.log(user);

        let request = {
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace("outcoming", "user") + '/list', {...request}).subscribe((response: any) => {
                this.commissions = response;
                console.log(response);

                this.onCommissionsChanged.next(this.commissions);
                resolve(this.commissions);
            }, reject);
        });
    }

    list(startDate: Date, endDate: Date): Promise<any> {
        let request = {
            development: this._authService.currentDevelopmentValue,
            startDate: startDate,
            endDate: endDate
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/list', {...request}).subscribe((response: any) => {
                this.commissions = response;

                this.onCommissionsChanged.next(this.commissions);
                resolve(this.commissions);
            }, reject);
        });
    }


    find(unityHistoryCommission: any): Promise<any> {
        console.log(unityHistoryCommission);
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/find/" + unityHistoryCommission.unityHistoryCommissionId).subscribe((response: any) => {
                this.commission = response;
                this.onCommissionChanged.next(this.commission);
                resolve(response);
            }, reject);
        });
    }
}
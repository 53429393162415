import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { EngineeringInput } from 'app/main/model/engineering/engineering-input.model';
import { EngineeringInputType } from 'app/main/model/engineering/engineering-input-type.model';
import { EngineeringMeasure } from 'app/main/model/engineering/engineering-measure.model';
import { conforms } from 'lodash';

@Injectable()
export class SupplyInventoryService
{
    apiUrl = environment.apiUrl + "/supply/inventory";

    engineeringInputs: any[];
    onEngineeringInputsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onEngineeringInputsChanged = new BehaviorSubject({});
    }

    list(engineeringInputType?: EngineeringInputType, database: string = "OWN"): Promise<EngineeringInput[]> {
        let request: any = {
            engineeringInputType: engineeringInputType,
            development : this._authService.currentDevelopmentValue,
            database: database
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/input/list", {...request}).subscribe((response: any[]) => {
                this.engineeringInputs = response;
                this.onEngineeringInputsChanged.next(this.engineeringInputs);
                resolve(this.engineeringInputs);
            });
        });
    }

    listInputGroup(): Promise<any> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/input/group/list", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }

    
    getInventoryData(engineeringInput: EngineeringInput, engineeringMeasure: EngineeringMeasure): Promise<any> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
            engineeringInput : engineeringInput,
            engineeringMeasure: engineeringMeasure
        };
 
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl, {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }

    createComment(comment, engineeringInput, engineeringMeasure): Promise<any> {
        let request = {
            comment: comment,
            development : this._authService.currentDevelopmentValue,
            engineeringInput : engineeringInput,
            engineeringMeasure: engineeringMeasure
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/comment/create", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    listComments(engineeringInput, engineeringMeasure): Promise<any> {
        let request = {
            development : this._authService.currentDevelopmentValue,
            engineeringInput : engineeringInput,
            engineeringMeasure: engineeringMeasure
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/comment/list", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    listInput(): Promise<EngineeringInput[]> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any[]) => {
                this.engineeringInputs = response;
                this.onEngineeringInputsChanged.next(this.engineeringInputs);
                resolve(this.engineeringInputs);
            });
        });
    }

    updateMinimumQuantity(supplyInventory) {
        let request: any = {
            id : supplyInventory.id,
            minimumQuantity: supplyInventory.minimumQuantity
        };

        console.log(supplyInventory);
        
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/minimumquantity", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';
import { MedicalCoordinator } from 'app/main/model/human-resources/medical-coordinator.model';

@Injectable()
export class MedicalCoordinatorService {
    apiUrl = environment.apiUrl + "/human/resources/medical/coordinator";

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    ){}

    createMedicalCoordinator(medicalCoordinator: MedicalCoordinator): Promise<any> {
        let request = this.mountRequestMedicalCoordinator(medicalCoordinator);            
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...request})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateMedicalCoordinator(medicalCoordinator): Promise<any> {
        let request = this.mountRequestMedicalCoordinator(medicalCoordinator);            
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/update', {...request})
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    deleteMedicalCoordinator(medicalCoordinator): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...medicalCoordinator})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    mountRequestMedicalCoordinator(medicalCoordinator: MedicalCoordinator): any {
        let request = {
            id: medicalCoordinator.id ? medicalCoordinator.id : null,
            name: medicalCoordinator.name,
            crm: medicalCoordinator.crm,
            cpf: medicalCoordinator.cpf,
            startDate: medicalCoordinator.startDate,
            endDate: medicalCoordinator.endDate,
        };  
        return request;
    }

    findActive(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.apiUrl + "/find")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }    

    list(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.apiUrl + "/list/")
                .subscribe((response: MedicalCoordinator[]) => {
                    resolve(response);
                }, reject);
        });
    }     
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

@Injectable()
export class ReportSalesDevelopmentService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/report/sales";
    routeParams: any;

    items: any;
    unities: any;
    unitiesPrice: any;
    onItemsChanged: BehaviorSubject<any>;
    onUnitiesChanged: BehaviorSubject<any>;
    onUnitiesPriceChanged: BehaviorSubject<any>;
    today: Date;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onItemsChanged = new BehaviorSubject({});
        this.onUnitiesChanged = new BehaviorSubject({});
        this.onUnitiesPriceChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
       
        return new Promise((resolve, reject) => {
            Promise.all([
                this.list(),
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get customers
     *
     * @returns {Promise<any>}
     */
    list(developmentId?): Promise<any> {
        if(!developmentId) {
            developmentId = this._authService.currentDevelopmentValue.id;
        }
        
        let params = {
            development: developmentId
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/development", {params: params})
                .subscribe((response: any) => {
                    this.items = response;
                    this.unities = this.items.unities;
                    this.onItemsChanged.next(this.items);
                    this.onUnitiesChanged.next(this.unities);
                    resolve(response);
                }, reject);
        });
    }

    priceHistory(date: Date, developmentId?): Promise<any> {
        if(!developmentId) {
            developmentId = this._authService.currentDevelopmentValue.id;
        }
        
        let request = {
            date : date,
            development: developmentId
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/development/unityPrice", {...request})
                .subscribe((response: any) => {
                    this.unitiesPrice = response;
                    this.onUnitiesPriceChanged.next(this.unitiesPrice);
                    resolve(response);
                }, reject);
        });
    }

    getTotals(developmentId?): Promise<any> {
        if(!developmentId) {
            developmentId = this._authService.currentDevelopmentValue.id;
        }
        
        let request = {
            development: developmentId
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/development/totals", {...request})
                .subscribe((response: any) => {
                    console.log(response);
                    resolve(response);
                }, reject);
        });
    }

    updateUnitiesList(unities): void {
        this.onUnitiesChanged.next(unities);
    }
}
import { AnyDocument } from 'app/main/model/any-document.model';

export class DocumentType {
    id: string;
    description: string;
    translate: string;
    image: string;
    imageDefault: string;
    documentType: string;
    employeeHasDocument: boolean;
    employeeDocumentUrl: string;
    supplierHasDocument: boolean;
    supplierDocumentUrl: string;
    documentFile: AnyDocument;
    required: boolean;
    expirationDate: Date;
    
    constructor(documentType?) {
        documentType = documentType || {};
        this.id = documentType.id || '';
        this.description = documentType.description || '';
        this.translate = documentType.translate || '';
        this.image = documentType.image || '';
        this.imageDefault = documentType.imageDefault || '';
        this.documentType = documentType.documentType || '';
        this.employeeHasDocument = documentType.employeeHasDocument || '';
        this.employeeDocumentUrl = documentType.employeeDocumentUrl || '';
        this.supplierHasDocument = documentType.supplierHasDocument || '';
        this.supplierDocumentUrl = documentType.supplierDocumentUrl || '';
        this.documentFile = documentType.documentFile || ''; 
        this.required = documentType.required || ''; 
        this.expirationDate = documentType.expirationDate ? new Date(documentType.expirationDate) : null; 
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';
import { Rubric } from 'app/main/model/human-resources/rubric.model';

@Injectable()
export class RubricService {
    apiUrl = environment.apiUrl + "/human/resources/rubric";

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    ){}

    createRubric(rubric: Rubric): Promise<any> {
        let request = this.mountRequestRubric(rubric);            
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...request})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateRubric(rubric): Promise<any> {
        let request = this.mountRequestRubric(rubric);            
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/update', {...request})
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    deleteRubric(rubric): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...rubric})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    mountRequestRubric(rubric: Rubric): any {
        let request = {
            id: rubric.id ? rubric.id : null,
            name: rubric.name,
            description: rubric.description,
            esocial: rubric.esocial
        };  
        return request;
    }

    list(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list/")
                .subscribe((response: Rubric[]) => {
                    resolve(response);
                }, reject);
        });
    }
}

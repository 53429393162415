import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UnityHistoryExchangeType } from 'app/main/model/unity-history-exchange-type.model';

import { environment } from 'app/environment/environment';

@Injectable()
export class UnityHistoryExchangeTypeService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/unity/history/exchange/type";
    
    onExchangeTypesChanged: BehaviorSubject<any>;

    exchangeTypes: UnityHistoryExchangeType[];

    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onExchangeTypesChanged = new BehaviorSubject([]);
    }

    resolve(): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getExchangeTypes()
            ]).then(
                ([exchangeTypes]) => {
                    this.exchangeTypes = exchangeTypes;
                    this.onExchangeTypesChanged.next(this.exchangeTypes);
                    resolve();
                },
                reject
            );
        });
    }

    getExchangeTypes(): Promise<any> {
        return new Promise((resolve, reject) => {
                this._httpClient.get(this.apiUrl + '/list').subscribe((response: any) => {
                    this.exchangeTypes = response;

                    this.onExchangeTypesChanged.next(this.exchangeTypes);
                    resolve(this.exchangeTypes);
                }, reject);
            }
        );
    }
}
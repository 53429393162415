import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

@Injectable()
export class SupplyInventoryCenterService
{
    apiUrl = environment.apiUrl + "/supply/inventory/center";


    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
    }

    list(developmentRequest?): Promise<any> {
        let development;

        if(!developmentRequest) {
            development = this._authService.currentDevelopmentValue;
        } else {
            development = developmentRequest;
        }

        let request: any = {
           development : development
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }

    listInventoryItems(supplyInventoryCenter? : any): Promise<any> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
            supplyInventoryCenter: supplyInventoryCenter
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/inventory/list", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }

    create(description, isDefault): Promise<any> {
        let request = {
            development : this._authService.currentDevelopmentValue,
            description: description,
            isDefault: isDefault
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getRegistriesData(engineeringInput, engineeringMeasure, supplyInventoryCenter): Promise<any> {
        let request: any = {
            supplyInventoryCenter : supplyInventoryCenter,
            engineeringInput : engineeringInput,
            engineeringMeasure: engineeringMeasure
        };
    
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/transaction/list", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }
}
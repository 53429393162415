import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule, MatTableModule, MatToolbarModule } from '@angular/material';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

import { FinancingIndexerPeriodSearchModule } from 'app/main/configurations/development/financing-indexer-period-search/financing-indexer-period-search.module';
import { FinancingInstallmentIndexerFormModule } from 'app/main/financial/incoming/financing-installment-indexer-form/financing-installment-indexer-form.module';

import { FinancingIndexerSearchDialogComponent } from 'app/main/financial/incoming/financing-indexer-search/financing-indexer-search.component';

@NgModule({
    declarations: [
        FinancingIndexerSearchDialogComponent
    ],
    imports: [
        CommonModule,

        FormsModule,

        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatTableModule,
        MatToolbarModule,

        FinancingIndexerPeriodSearchModule,
        FinancingInstallmentIndexerFormModule,

        NgxMaskModule,
        ReactiveFormsModule,

        TranslateModule
    ],
    entryComponents: [
        FinancingIndexerSearchDialogComponent
    ],
})
export class FinancingIndexerSearchModule
{
}
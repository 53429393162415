import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';

@Injectable()
export class CountryService {
    apiUrl = environment.apiUrl;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {}

    /**
     * Search Postal Code
     *
     * @returns {Promise<any>}
     */
    listCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/country/list").subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

}
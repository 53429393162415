import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

import { Development } from 'app/main/configurations/development/development.model';
import { BankLoan } from 'app/main/model/bank-loan.model';

@Injectable()
export class BankLoanService
{
    apiUrl = environment.apiUrl + "/bank/loan";
    bankLoans: BankLoan[];
    onBankLoansChanged: BehaviorSubject<any>;

    bankLoan: BankLoan;
    onBankLoanChanged: BehaviorSubject<any>;

    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onSelectedBankLoansChanged: BehaviorSubject<any>;

    selectedBankLoans: string[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onBankLoansChanged = new BehaviorSubject({});
        this.onBankLoanChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onSelectedBankLoansChanged = new BehaviorSubject([]);

        this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.list();
        });

        this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.list();
        });
    }

    /**
     * Lists commission types
     *
     * @returns {Promise<any>}
     */
    list(development?: Development, startDate?: Date, endDate?: Date): Promise<any> {
        if(!development) {
            development = this._authService.currentDevelopmentValue;
        }

        let request = {
            development: development,
            startDate: startDate,
            endDate: endDate
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: BankLoan[]) => {
                this.bankLoans = response;
                this.onBankLoansChanged.next(this.bankLoans);

                if ( this.searchText && this.searchText !== '' ) {
                    this.bankLoans = FuseUtils.filterArrayByString(this.bankLoans, this.searchText);
                }

                this.bankLoans = this.bankLoans.map(bankLoan => {
                    return new BankLoan(bankLoan);
                });

                this.onBankLoansChanged.next(this.bankLoans);
                resolve(response);
            }, reject);
        });
    }

    /**
     * Find bankLoan
     *
     * @returns {Promise<any>}
     */
    find(bankLoan: BankLoan, startDate?: Date, endDate?: Date): Promise<any> {
        let request = {
            id: bankLoan.id,
            startDate: startDate,
            endDate: endDate
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/find/", {...request}).subscribe((response: BankLoan) => {
                this.bankLoan = new BankLoan(response);
                this.onBankLoanChanged.next(this.bankLoan);
                resolve(response);
            }, reject);
        });
    }

    create(bankLoan: BankLoan): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...bankLoan}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    update(bankLoan: BankLoan): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update", {...bankLoan}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    delete(bankLoan: BankLoan): Promise<any> {
        let request = {
            id: bankLoan.id,
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...request}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    /**
     * Deselect outcomingPayments
     */
    deselectBankLoans(): void
    {
        this.selectedBankLoans = [];

        // Trigger the next event
        this.onSelectedBankLoansChanged.next(this.selectedBankLoans);
    }
}
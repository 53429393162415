import { Bank } from 'app/main/model/bank.model';
import { Development } from 'app/main/configurations/development/development.model';
import { User } from 'app/main/configurations/user/user.model';

export class BankAccount {
    id: string;
    bank: Bank;
    description: string;
    bankAgency: string;
    bankAccount: string;
    bankAccountId: string;
    initialBalance: number;
    initialBalanceDate: Date;
    initialReconciliationDate: Date;
    accountBalance: number;
    development: Development;
    allDevelopments: boolean;
    countToReconcile: number;
    createdBy: User;
    creationDate: Date;
    
    constructor(bankAccount?) {
        bankAccount = bankAccount || {};
        this.id = bankAccount.id || '';
        this.bank = bankAccount.bank || '';
        this.description = bankAccount.description || '';
        this.bankAgency = bankAccount.bankAgency || '';
        this.bankAccount = bankAccount.bankAccount || '';
        this.bankAccountId = bankAccount.bankAccountId || '';
        this.initialBalance = bankAccount.initialBalance || '';
        this.initialBalanceDate = bankAccount.initialBalanceDate ? new Date(bankAccount.initialBalanceDate) : null;
        this.initialReconciliationDate = bankAccount.initialReconciliationDate ? new Date(bankAccount.initialReconciliationDate) : null;
        this.accountBalance = bankAccount.accountBalance || '';
        this.countToReconcile = bankAccount.countToReconcile || '';
        this.development = bankAccount.development || '';
        this.allDevelopments = bankAccount.allDevelopments || false;
        this.createdBy = bankAccount.createdBy || '';
        this.creationDate = bankAccount.creationDate || '';
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

import { UnitySubstatus } from 'app/main/model/unity-substatus.model';

@Injectable()
export class UnitySubstatusService
{
    apiUrl = environment.apiUrl + "/unity/substatus";
    onUnityStatusesChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onUnityStatusesChanged = new BehaviorSubject({});
    }

    /**
     * Lists unity statuses
     *
     * @returns {Promise<any>}
     */
    list(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list")
                .subscribe((response: UnitySubstatus[]) => {
                    this.onUnityStatusesChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }

    listSubstatusHistory(unityHistory): Promise<any> {
        let request = {
            unityHistory: unityHistory
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/history" + "/list", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
   
    listSubstatusHistoryComment(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/comment" + "/list/" + id).subscribe((response: any) => {
                resolve(response);
            })
        });
    }
}
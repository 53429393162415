
import { EngineeringMeasure } from 'app/main/model/engineering/engineering-measure.model';
import { EngineeringInput } from '../engineering/engineering-input.model';
import { SupplyOrder } from './supply-order.model';
import { SupplyTransaction } from './supply-transaction.model';

export class SupplyOrderEngineeringInput {
    id: string;
    supplyOrder: SupplyOrder;
    engineeringInput: EngineeringInput
    engineeringMeasure: EngineeringMeasure;
    coefficient: number;
    unityValue: number;
    receivedQuantity: number; 
    allocatedQuantity: number;
    obs: string;
    transactions: SupplyTransaction[];
    selected: boolean;
    issuedQuantity: number;
    
    constructor(supplyOrderEngineeringInput?)
    {
        supplyOrderEngineeringInput = supplyOrderEngineeringInput || {};
        this.id = supplyOrderEngineeringInput.id || '';
        this.supplyOrder = supplyOrderEngineeringInput.supplyOrder || '';
        this.engineeringInput = supplyOrderEngineeringInput.engineeringInput || '';
        this.engineeringMeasure = supplyOrderEngineeringInput.engineeringMeasure || '';
        this.coefficient = supplyOrderEngineeringInput.coefficient || '';
        this.unityValue = supplyOrderEngineeringInput.unityValue || '';
        this.receivedQuantity = supplyOrderEngineeringInput.receivedQuantity || '';
        this.allocatedQuantity = supplyOrderEngineeringInput.allocatedQuantity || 0;
        this.obs = supplyOrderEngineeringInput.obs || '';
        this.transactions = supplyOrderEngineeringInput.transactions || '';
        this.selected = false;
        this.issuedQuantity = supplyOrderEngineeringInput.issuedQuantity || 0;
    }

}
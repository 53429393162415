import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'app/auth/authentication.service';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from 'app/environment/environment';

import { Development } from 'app/main/configurations/development/development.model';
import { AnyDocument } from 'app/main/model/any-document.model';
import { RatingRegistry } from 'app/main/model/rating-registry.model';
import { Supplier } from 'app/main/model/supplier.model';

@Injectable()
export class SupplyPurchasesService
{
    apiUrl = environment.apiUrl + "/supply/purchase";

    supplyPurchases: any[];
    onSupplyPurchasesChanged: BehaviorSubject<any>;
    
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    searchText: string;
    filterBy: string;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onSupplyPurchasesChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    listBySupplier(supplier, startDate?: Date, endDate?: Date, supplyPurchaseStatuses?: any[]): Promise<any> {
        let development  = this._authService.currentDevelopmentValue;

        let request = {
            development: development,
            supplier: supplier,
            startDate: startDate,
            endDate: endDate,
            supplyPurchaseStatuses: supplyPurchaseStatuses
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any) => {
                this.supplyPurchases = response;
                this.onSupplyPurchasesChanged.next(response);
                resolve(response);
            }, reject);
        });
    }

    list(isFromInvoice : boolean = false, startDate?, endDate?, purchaseStatuses?): Promise<any> {     
        let request = {
            development: this._authService.currentDevelopmentValue,
            startDate: startDate,
            endDate: endDate,
            isFromInvoice : isFromInvoice,
            supplyPurchaseStatuses: purchaseStatuses
        };
  
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any) => {
                this.supplyPurchases = response;
                this.onSupplyPurchasesChanged.next(this.supplyPurchases);
                resolve(this.supplyPurchases);
            }, reject);
        });       
    }

    delete(supplyPurchases: any): Promise<any> {
        let request = {
            id: supplyPurchases.id,
        };
    
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", request).subscribe((response: any) => {
                this.list();
                resolve(response);
            }, reject);
        });
    }
    
    create(supplyOrder: any, supplyInvoice: any, development: Development, purchase: any, supplier: Supplier, engineeringSuppliesInputs: any[],
            document: AnyDocument , ratings: RatingRegistry[]): Promise<any> {
        let request = {
            development : development,
            supplier: supplier,
            description: purchase.description,
            obs: purchase.obs,
            engineeringInputs: engineeringSuppliesInputs,
            document: document,
            ratings: ratings,
            supplyOrder: supplyOrder,
            supplyInvoice: supplyInvoice
        }

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/create", {...request}).subscribe((response) => {
                resolve(response);
                this.list();
            });
        });
    
    }

    cancel(supplyPurchase): Promise<any> {
        let request = {
            supplyPurchase: supplyPurchase,
            development: this._authService.currentDevelopmentValue,
        };
    
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/cancel", request).subscribe((response: any) => {
                this.list();
                resolve(response);
            }, reject);
        });
    }

    listStatus(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/status/list").subscribe((response: any) => {
                this.list();
                resolve(response);
            }, reject);
        });
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';

import { AuthenticationService } from 'app/auth/authentication.service';

import { UnityHistoryComment } from 'app/main/model/unity-history-comment.model';
import { UnityHistory } from 'app/main/model/unity-history.model';

@Injectable()
export class UnityHistoryCommentService
{
    apiUrl = environment.apiUrl + "/sales/unity/history/comment";

    routeParams: any;
    unityHistoryComments: UnityHistoryComment[];
    onUnityHistoryCommentsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onUnityHistoryCommentsChanged = new BehaviorSubject({});
    }

    create(unityHistoryComment: UnityHistoryComment): Promise<any> {
        let request = {
            message: unityHistoryComment.message,
            unityHistory: unityHistoryComment.unityHistory,
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    list(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/list/' + id).subscribe((response: any) => {
                this.unityHistoryComments = response;
                this.onUnityHistoryCommentsChanged.next(this.unityHistoryComments);
                resolve(response);
            })
        });
    }

    delete(unityHistoryComment: UnityHistoryComment): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...unityHistoryComment}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
}
import { EngineeringInput } from '../engineering/engineering-input.model';
import { SupplyTransaction } from './supply-transaction.model';
import { SupplyOrderEngineeringInput } from './supply-order-engineering-input.model';
import { SupplyInvoiceEngineeringInputItemType } from './supply-invoice-engineering-input-item-type.model';
import { SupplyInvoiceInput } from './supply-invoice-input.model';

export class SupplyInvoiceEngineeringInputItem {
    id: number;
    supplyInvoiceEngineeringInputItemType: SupplyInvoiceEngineeringInputItemType;
    coefficient: number;
    engineeringInput: EngineeringInput;
    supplyOrderEngineeringInput: SupplyOrderEngineeringInput;
    supplyInvoiceEngineeringInput: SupplyInvoiceInput;
    receivedQuantity: number;
    supplyTransaction: SupplyTransaction;
    returnedSupplyInvoiceInput: SupplyInvoiceInput;
    returnedTransactions: SupplyTransaction[];
    returnedQuantity: number;
    expanded?: boolean = false;

    constructor(supplyInvoiceEngineeringInputItem?)
    {
        supplyInvoiceEngineeringInputItem = supplyInvoiceEngineeringInputItem || {};
        this.id = supplyInvoiceEngineeringInputItem.id || '';
        this.supplyInvoiceEngineeringInputItemType = supplyInvoiceEngineeringInputItem.supplyInvoiceEngineeringInputItemType || '';
        this.coefficient = supplyInvoiceEngineeringInputItem.coefficient || '';
        this.engineeringInput = supplyInvoiceEngineeringInputItem.engineeringInput || '';
        this.supplyOrderEngineeringInput = supplyInvoiceEngineeringInputItem.supplyOrderEngineeringInput || ''
        this.supplyTransaction = supplyInvoiceEngineeringInputItem.supplyTransaction || '';
        this.receivedQuantity = supplyInvoiceEngineeringInputItem.receivedQuantity || '';
        this.supplyInvoiceEngineeringInput = supplyInvoiceEngineeringInputItem.supplyInvoiceEngineeringInput || '';
        this.returnedSupplyInvoiceInput = supplyInvoiceEngineeringInputItem.returnedSupplyInvoiceInput || '';
        this.returnedTransactions = supplyInvoiceEngineeringInputItem.returnedTransactions || '';
        this.returnedQuantity = supplyInvoiceEngineeringInputItem.returnedQuantity || '';
    }

}
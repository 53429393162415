import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';
import { identifierName } from '@angular/compiler';


@Injectable()
export class UntityWarrantyService
{
    apiUrl = environment.apiUrl + "/postconstruction/warranty/unity";

    unityWarranties: any[];
    onUnityWarrantiesChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onUnityWarrantiesChanged = new BehaviorSubject({});
    }

    list(): Promise<any> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any) => {
                this.unityWarranties= response;
                this.onUnityWarrantiesChanged.next(this.unityWarranties);
                resolve(this.unityWarranties);
            });
        });
    }

    create(startDate, unities, warranties): Promise<any> {
        let request = {
            startDate: startDate,
            unities : unities,
            warranties: warranties,
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/create", {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }

    listByUnityAndEngineeringService(unity, engineeringService): Observable<any> {
        let request: any = {
            unity: unity,
            engineeringService: engineeringService,
        };
    
        // Diretamente retornando um Observable da chamada HTTP
        return this._httpClient.post(this.apiUrl + "/service/list", request);
    }

    // listEngineeringServiceByUnity(unity): Observable<any> {
    //     let request: any = {
    //         unity: unity,
    //     };
    
    //     // Diretamente retornando um Observable da chamada HTTP
    //     return this._httpClient.post(this.apiUrl + "/service/list", request);
    // }
    
    listEngineeringServiceByUnity(unity): Promise<any> {
        let request: any = {
            unity: unity,
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/service/list", {...request}).subscribe((response) => {
                console.log(response);
                resolve(response);
            });
        });
    }
  
    listUnitiesByUser(user): Promise<any> {
        let request: any = {
            development : this._authService.currentDevelopmentValue,
            user: user
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/user/list", {...request}).subscribe((response) => {
                console.log(response);
                resolve(response);
            });
        });
    }
}
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { environment } from "app/environment/environment";
import { MedicalCoordinator } from "app/main/model/human-resources/medical-coordinator.model";
import { Clinic } from "app/main/model/human-resources/clinic.model";

@Injectable()
export class SettingsClinicsService implements Resolve<any> {
    apiUrl = environment.apiUrl + "/human/resources/clinic";
    onClinicsChanged: BehaviorSubject<any>;
    clinics: Clinic[];

    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    searchText: string;
    filterBy: string;

    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.onClinicsChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.list()]).then(() => {
                this.onSearchTextChanged.subscribe((searchText) => {
                    this.searchText = searchText;
                    this.list();
                });

                this.onFilterChanged.subscribe((filter) => {
                    this.filterBy = filter;
                    this.list();
                });

                resolve();
            }, reject);
        });
    }

    list(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.apiUrl + "/list/")
                .subscribe((response: Clinic[]) => {
                    this.clinics = response;
                    this.clinics = this.clinics.map(
                        (clinic) => {
                            return new Clinic(clinic);
                        }
                    );
                    this.onClinicsChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
}

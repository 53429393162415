import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

import { Development } from 'app/main/configurations/development/development.model';
import { BankAccount } from 'app/main/model/bank-account.model';
import { Bank } from 'app/main/model/bank.model';

@Injectable()
export class BankAccountService
{
    apiUrl = environment.apiUrl + "/bank/account";
    bankAccounts: BankAccount[];
    onBankAccountsChanged: BehaviorSubject<any>;

    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onSelectedBankAccountsChanged: BehaviorSubject<any>;

    selectedBankAccounts: string[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onBankAccountsChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onSelectedBankAccountsChanged = new BehaviorSubject([]);

        this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.list();
        });

        this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.list();
        });
    }

    /**
     * Lists commission types
     *
     * @returns {Promise<any>}
     */
    list(development?: Development): Promise<any> {
        let developmentRequest = this._authService.currentDevelopmentValue;
        if(development) {
            developmentRequest = development;
        }
        
        let request = {
            development: developmentRequest.id
        };

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list", {params: request})
                .subscribe((response: BankAccount[]) => {
                    this.bankAccounts = response;
                    this.onBankAccountsChanged.next(this.bankAccounts);

                    if ( this.searchText && this.searchText !== '' ) {
                        this.bankAccounts = FuseUtils.filterArrayByString(this.bankAccounts, this.searchText);
                    }

                    this.bankAccounts = this.bankAccounts.map(bankAccount => {
                        return new BankAccount(bankAccount);
                    });

                    this.onBankAccountsChanged.next(this.bankAccounts);
                    resolve(response);
                }, reject);
        });
    }

    create(bankAccount: BankAccount): Promise<any> {
        let request = {
            bank: bankAccount.bank,
            description: bankAccount.description,
            bankAgency: bankAccount.bankAgency,
            bankAccount: bankAccount.bankAccount,
            bankAccountId: bankAccount.bankAccountId,
            initialBalance: bankAccount.initialBalance,
            initialBalanceDate: bankAccount.initialBalanceDate,
            initialReconciliationDate: bankAccount.initialReconciliationDate,
            accountBalance: bankAccount.initialBalance,
            allDevelopments: bankAccount.allDevelopments,
            development: this._authService.currentDevelopmentValue
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...request}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    update(bankAccount: BankAccount): Promise<any> {
        let request = {
            id: bankAccount.id,
            bank: bankAccount.bank,
            description: bankAccount.description,
            bankAgency: bankAccount.bankAgency,
            bankAccount: bankAccount.bankAccount,
            bankAccountId: bankAccount.bankAccountId,
            initialBalance: bankAccount.initialBalance,
            initialBalanceDate: bankAccount.initialBalanceDate,
            initialReconciliationDate: bankAccount.initialReconciliationDate,
            accountBalance: bankAccount.accountBalance,
            allDevelopments: bankAccount.allDevelopments,
            development: this._authService.currentDevelopmentValue
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update", {...request}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    delete(bankAccount: BankAccount): Promise<any> {
        let request = {
            id: bankAccount.id,
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...request}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    listByDevelopments(developments: Development[]): Promise<any> {
        let request = {
            developments: developments
        };
        console.log(request);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/development/list", {...request}).subscribe((response) => {
                this.list();
                resolve(response);
            });
        });
    }

    /**
     * Deselect bankAccounts
     */
    deselectBankAccounts(): void
    {
        this.selectedBankAccounts = [];

        // Trigger the next event
        this.onSelectedBankAccountsChanged.next(this.selectedBankAccounts);
    }
}
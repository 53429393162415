import { Development } from 'app/main/configurations/development/development.model';
import { Assignor } from 'app/main/model/assignor.model';
import { User } from 'app/main/configurations/user/user.model';
import { BankLoanStatus } from 'app/main/model/bank-loan-status.model';
import { InterestType } from 'app/main/model/interest-type.model';
import { BankStatementFinancingInstallment } from './bank-statement-financing-installment.model';
import { BankStatementOutcomingPayment } from './bank-statement-outcoming-payment.model';

export class BankLoan {
    id: string;
    development: Development;
    assignor: Assignor;
    description: string;
    loanValue: number;
    loanDate: Date;
    interestRate: number;
    interestType: InterestType;
    stringencyDate: Date;
    transactionAmount: number;
    transactionDate: Date;
    returnedAmount: number;
    finalPeriodBalance: number;
    initialPeriodBalance: number;
    returnDate: Date;
    createdBy: User;
    creationDate: Date;
    bankLoanIncomingBankStatements: BankStatementFinancingInstallment[];
    bankLoanOutcomingBankStatements: BankStatementOutcomingPayment[];
    
    /**
     * Constructor
     *
     * @param bankLoan
     */
    constructor(bankLoan?) {
        bankLoan = bankLoan || {};
        this.id = bankLoan.id || '';
        this.description = bankLoan.description || '';
        this.development = bankLoan.development || false;
        this.assignor = bankLoan.assignor || '';
        this.loanValue = bankLoan.loanValue || '';
        this.loanDate = bankLoan.loanDate ? new Date(bankLoan.loanDate) : null;
        this.interestRate = bankLoan.interestRate || '';
        this.interestType = bankLoan.interestType || '';
        this.stringencyDate = bankLoan.stringencyDate ? new Date(bankLoan.stringencyDate) : null;
        this.transactionAmount = bankLoan.transactionAmount || '';
        this.transactionDate = bankLoan.transactionDate ? new Date(bankLoan.transactionDate) : null;
        this.returnedAmount = bankLoan.returnedAmount || '';
        this.returnDate = bankLoan.returnDate ? new Date(bankLoan.returnDate) : null;
        this.createdBy = bankLoan.createdBy || '';
        this.creationDate = bankLoan.creationDate || '';
        this.initialPeriodBalance = bankLoan.initialPeriodBalance || '';
        this.finalPeriodBalance = bankLoan.finalPeriodBalance || '';
        this.bankLoanIncomingBankStatements = bankLoan.bankLoanIncomingBankStatements || [];
        this.bankLoanOutcomingBankStatements = bankLoan.bankLoanOutcomingBankStatements || [];
    }

    getBalance(): number {
        return this.transactionAmount - this.returnedAmount;
    }

    getBalanceColor(): string {
        if((this.transactionAmount - this.returnedAmount) > 0) {
            return 'green'
        } else {
            return 'red';
        }
    }
}
export class Clinic {
    id: string;
    name: string;
    cnpj: string;
    accessDate: Date;
    shutdownDate: Date;
    doAppointment: Boolean;
    doExam: Boolean;

    constructor(clinic?) {
        clinic = clinic || {};
        this.id = clinic.id || "";
        this.name = clinic.name || "";
        this.cnpj = clinic.cnpj || "";
        this.accessDate = clinic.accessDate ? new Date(clinic.accessDate) : null;
        this.shutdownDate = clinic.shutdownDate ? new Date(clinic.shutdownDate) : null;
        this.doAppointment = clinic.doAppointment || "";
        this.doExam = clinic.doExam || "";
    }
}

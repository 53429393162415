import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule, MatDividerModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule,  MatTableModule, MatMenuModule, MatToolbarModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChangeSubstatusFormModule } from 'app/main/sales/sales-unity/change-substatus-form/change-substatus-form.module';
import { ObsDialogModule } from 'app/component/ume-obs-dialog/ume-obs-dialog.module';
import { TranslateModule } from '@ngx-translate/core';

import { SupplyBudgetTimelineComponent } from './supply-budget-timeline.component';
import { SupplyBudgetLogService } from 'app/main/service/supply/supply-budget-log.service';

@NgModule({
    declarations: [
        SupplyBudgetTimelineComponent 
    ],
    imports: [
        FuseSharedModule,
        
        MatDatepickerModule,
        MatDividerModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatToolbarModule,
        MatTableModule,
        ChangeSubstatusFormModule,
        ObsDialogModule,
        
        FormsModule,
        ReactiveFormsModule,

        TranslateModule
    ],
    providers: [
        SupplyBudgetLogService 
    ],
    entryComponents: [
        SupplyBudgetTimelineComponent 
    ],
})
export class SupplyBudgetTimelineModule
{
}

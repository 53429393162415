import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';

@Injectable()
export class SupplyPurchaseFormService implements Resolve<any> {
    apiUrl = environment.apiUrl + "/supply/purchase";

    onSupplyPurchaseChanged: BehaviorSubject<any>;
    selectedSuppliers: string[] = [];
    routeParams: any;

    purchase: any;

    supplierType: number;

    constructor(
        private _httpClient: HttpClient,
    )
    {
        // Set the defaults
        this.onSupplyPurchaseChanged = new BehaviorSubject({});

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
        
            Promise.all([
                this.find()
            ]).then(
                ([files]) => {
                    resolve();
                },
                reject
            );
        });
    }

    find(suplierId?): Promise<any> {
        let id = suplierId || this.routeParams.id;
 
        return new Promise((resolve, reject) => {
            if (this.routeParams && id === 'new' ) {
                this.onSupplyPurchaseChanged.next(false);
                resolve(false);
            }
            else {
                this._httpClient.get(this.apiUrl + "/find/" + id)
                    .subscribe((response: any) => {
                        this.purchase = response;
                        this.onSupplyPurchaseChanged.next(this.purchase);
                         resolve(response);
                    }, reject);
            }
        });
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { EngineeringBudgetingSection } from 'app/main/model/engineering/engineering-budgeting-section.model';
import { EngineeringBudgetingSectionActivityItemRequest } from 'app/main/engineering/budgeting/budgeting-planning/models/engineering-budgeting-section-activity-item-request.model';
import { EngineeringActivity } from 'app/main/model/engineering/engineering-activity.model';

@Injectable()
export class EngineeringBudgetingSectionInputActivityService
{
    apiUrl = environment.apiUrl + "/engineering/budgeting/section/input/service";
    
    constructor(
        private _httpClient: HttpClient,
        private _authActivity: AuthenticationService
    )
    {}

    list(engineeringActivity: EngineeringActivity): Promise<any> {
        let request: any = {
            engineeringActivityId: engineeringActivity.id
        };

        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + '/list/' + engineeringActivity.id).subscribe((response) => {
                console.log(response);
                resolve(response);
            });
        });
    }
    
    create(request: any): Promise<any> {
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + '/create', {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }
    
    update(engineeringBudgetingSection: EngineeringBudgetingSection, engineeringBudgetingSectionActivities: EngineeringBudgetingSectionActivityItemRequest[]): Promise<any> {
        let request = {
            engineeringBudgetingSection: engineeringBudgetingSection,
            engineeringBudgetingSectionActivities: engineeringBudgetingSectionActivities
        };

        return new Promise((resolve) => {
            this._httpClient.put(this.apiUrl + '/update', {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }

    delete(engineeringBudgetingSection: EngineeringBudgetingSection): Promise<any> {
        let request = {
            engineeringBudgetingSection: engineeringBudgetingSection
        };

        return new Promise((resolve) => {
            this._httpClient.put(this.apiUrl + '/delete', {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }

}